.MuiToolbar-root {
  background-color: #fff;
  color: #1577be;
}

.MuiDialogContent-root {
  background-color: #fff;
  color: #555;
}
.MuiDialogContent-root .MuiSelect-root {
  color: #1577be;
}
.MuiDialogContent-root .MuiInput-underline:after {
  border-bottom: 2px solid #1577be;
}
.MuiDialogContent-root .MuiSvgIcon-root {
  color: #1577be;
}
.MuiIconButton-label,
.MuiButton-label {
  color: #1577be;
}
.menu-logo {
  margin: auto 1em;
  height: 2em;
}
